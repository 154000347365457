<template>
  <Teleport to="body">
    <UIBaseTransition name="fade">
      <Component
        :is="UIStore.getDynamicPopup.component"
        v-bind="UIStore.getDynamicPopup.data"
        v-model="UIStore.getDynamicPopup.value"
        v-on="UIStore.getDynamicPopup.events"
        @update:model-value="UIStore.cleanPopup"
      />
    </UIBaseTransition>
  </Teleport>
</template>

<script setup lang="ts">
import useUIStore from '@/store/ui'

const UIStore = useUIStore()
</script>

<style scoped></style>
